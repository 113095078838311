<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Penjualan Apotek</b></div>
      <b-button
        squared
        variant="success"
        v-if="manipulateBtn == true"
        @click="$router.push('/pharmacy/cashier')"
      >Tambah</b-button>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row align-items-center justify-content-start mt-n1">
              <div class="col-md-6">
                <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('days')"  v-bind:class="{'btn-info' : btnActive.days, 'btn-outline-info' : btnActive.days == false   }">Hari Ini</button>
                <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('month')"  v-bind:class="{'btn-info' : btnActive.month, 'btn-outline-info' : btnActive.month == false  }">Bulan Ini</button>
                <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('lastMonth')"  v-bind:class="{'btn-info' : btnActive.lastMonth, 'btn-outline-info' : btnActive.lastMonth == false  }">Bulan Kemarin</button>
                <button class="btn mx-1 btn-timeseries " @click="btnActiveOnClick('year')"  v-bind:class="{'btn-info' : btnActive.year, 'btn-outline-info' : btnActive.year == false  }">Tahun Ini</button>
                <button class="btn mx-1 btn-timeseries "  v-bind:class="{'btn-info' : btnActive.period, 'btn-outline-info' : btnActive.period == false  }" @click="btnPeriodeOnClick">Periode</button>
              </div>

              <div class="col-md-3">
                <treeselect
                  v-model="filter.doctor_id"
                  :multiple="false"
                  :options="doctors"
                  :clearable="true"
                  placeholder="Cari Berdasar Dokter"
                />
              </div>
              <div class="col-md-3">
                <b-input-group>
                  <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input
                    type="text"
                    v-model="filter.patient_name"
                    placeholder="Cari Nama Pasien"
                    @keyup="filterByPatient"
                  ></b-form-input>
                </b-input-group>
              </div>
            </div>

            <!-- period date -->
            <div class="row">
              <div class="col-md-2">
                <span
                  style="width: 150px; color: #245590 !important"
                  class="font-weight-bold"
                  >{{recordData}} Baris Data</span>
              </div>

              <div class="col-md-6" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Tanggal Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button
                      squared
                      variant="light"
                    >s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Tanggal Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #prepend>
                      <b-button
                        squared
                        @click="filterByPeriode"
                        variant="success"
                      >Cari</b-button>
                      <b-button
                        squared
                        @click="resetFilterPeriode"
                        variant="danger"
                      >Reset</b-button>
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                       
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Tanggal Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button
                          squared
                          variant="light"
                        >s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Tanggal Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                         <template #prepend>
                          <b-button
                            squared
                            @click="filterByPeriode"
                            variant="success"
                          >Cari</b-button>
                          <b-button
                            squared
                            @click="resetFilterPeriode"
                            variant="danger"
                          >Reset</b-button>
                        </template>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>

            <!-- Table -->
            <b-table
              class="mt-3"
              striped
              hover
              responsive
              :items="items"
              :fields="fields"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'actions' ? '10%' : '' }"
                />
              </template>
              <template #cell(surcharge)="data">
                {{
                  "Rp " + parseInt(data.item.surcharge).toLocaleString("id-ID")
                }}
              </template>
              <template #cell(final_amount)="data">
                {{
                  "Rp " +
                  parseInt(data.item.final_amount).toLocaleString("id-ID")
                }}
              </template>
              <template #cell(actions)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  @click="
                    $router.push({
                      path: '/pharmacy/sales/detail/' + data.item.id,
                    })
                  "
                >
                  Info
                </b-button>
              </template>
            </b-table>
            <b-pagination
              v-if="items.length != 0"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              aria-controls="my-table"
              @page-click="pageOnClick"
              class="mt-4"
            ></b-pagination>
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';

export default {
  components: {
    Card,
    Treeselect,
  },

  data() {
    return {
      // Filter
      filter: {
        doctor_id: "",
        patient_name: "",
        start_date: "",
        end_date: "",
      },
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days : true,
        month : false,
        lastMonth : false,
        year : false,
        period : false,
      },
      lastPeriodType : "days",
      // Options
      doctors: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      recordData : 0,
      // Table Header
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "code",
          label: "Kode",
          sortable: false,
        },
        {
          key: "doctor_name",
          label: "Dokter",
          sortable: false,
        },
        {
          key: "patient_name",
          label: "Pasien",
          sortable: false,
        },
        {
          key: "display_transaction_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "surcharge",
          label: "Surcharge",
          sortable: true,
        },
        {
          key: "final_amount",
          label: "Total",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      items: [],
      manipulateBtn: false,
    };
  },

  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&doctor_id=${this.filter.doctor_id}&patient_name=${this.filter.patient_name}`;
      let response = await module.paginate(
        "pharmacy-orders",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
    },

    async getDoctorsOption() {
      let response = await module.setDoctorTreeSelect("doctors");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Cari Berdasar Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    resetFilter() {
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.filter.doctor_id = "";
    },

    resetFilterPeriode() {
      this.filter = validation.clearForm(this.filter);
      this.btnActiveOnClick(this.lastPeriodType)
      this.pagination();
    },

    async filterByPeriode() {
      this.pagination();
    },

    async filterByPatient() {
      // Reset Other Filter
      this.resetFilter();
      
      if(this.filter.parent_name == ''){
        this.btnActiveOnClick(this.lastPeriodType)
      }
      this.pagination();
    },

    async filterByDoctor(evt) {
      this.filter.doctor_id = evt.id;
      this.pagination();
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        console.log("looping");

        if (access_right[a] == "1402") {
          this.manipulateBtn = true;
        }
      }
    },

    btnPeriodeOnClick(){
      if(this.onShowPeriod == false){
        this.onShowPeriod = true
        this.btnActiveOnClick('period')
      }else{
        this.onShowPeriod = false
        this.btnActiveOnClick('period')
      }
    },

    btnActiveOnClick(type){
      if(type == 'days'){
        // set time
        this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = true
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "days"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'month'){
        // set time
        this.filter.start_date = moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = true
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "month"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'lastMonth'){
        // set time
        this.filter.start_date = moment().subtract(1,'months').startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().subtract(1,'months').endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss')


        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = true
        this.btnActive.year = false
        this.btnActive.period = false

        this.lastPeriodType = "lastMonth"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'year'){
        // set time
        this.filter.start_date = moment().startOf('year').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.filter.end_date = moment().endOf('year').endOf('day').format('YYYY-MM-DD HH:mm:ss')

        // get data
         this.pagination()
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = true
        this.btnActive.period = false

        this.lastPeriodType = "year"

        // hide period
        this.onShowPeriod = false
      }else if(type == 'period'){
        // set time
        this.filter.start_date = ""
        this.filter.end_date = ""
        
        // set btn Active
        this.btnActive.days = false
        this.btnActive.month = false
        this.btnActive.lastMonth = false
        this.btnActive.year = false
        this.btnActive.period = true
      }
    },
  },

  watch: {
    "filter.doctor_id": function (newVal, oldVal) {
      this.filter.doctor_id = typeof newVal === "undefined" ? "" : newVal;
      this.pagination();
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route: "" },
      { title: "Penjualan" },
    ]);
    this.pagination();
    this.getDoctorsOption();
    this.setActiveMenu();
  },
};
</script>

<style>
</style>